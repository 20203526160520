//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chevronRightWhite, sparkleWhite } from '@/static/icons/index.js';
import { ROUTES } from '~/constants/pageRoutes';
import analyticEvents, { ANALYTIC_SOURCE_MODULES } from '~/services/analyticEvents';

export default {
    name: 'GMATPrepHook',
    props: {
        icon: {
            default: sparkleWhite
        },
        title: {
            default: 'GMAT Prep is LIVE Now!',
            type: String,
            require: true
        },
        url: {
            default: ROUTES.GMAT_BOOK_DEMO,
            type: String,
            require: true
        },
        source: {
            default: ANALYTIC_SOURCE_MODULES.HOMEPAGE_LABEL,
            type: String,
            require: true
        }
    },
    data: () => {
        return {
            chevronRightWhite
        };
    },
    methods: {
        handleTrackEvent() {
            const trackingEvent = {
                event: analyticEvents.gmat_live_classes_clicked,
                data: {
                    source: this.source
                }
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};
